import axios from 'axios'
import { isEmpty } from 'lodash-es'
import { WebsocketProvider } from 'y-websocket'
import * as Y from 'yjs'
// @ts-ignore
import { AceBinding } from '@/assets/javascript/y-ace.js'

import { ADVANCEDIDETYPE } from '@/utils/ide'

import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'
import { useProjectManager } from '@/stores/projectManager.store'

import type { ISyncRequest } from '@/services/ide/projectTree.service'
import type { IProject, IsaveProjectActualRequest } from '@/services/ide/projects.service'

let binding: any = null
let provider: any = null

/**
 * @description - post the script to the server
 * @param script - passing the script as params
 * @param count - send the count
 * @returns call the function after execution of func
 */
const postSetScript = async (script: string, count: number = 0) => {
  if (!useIdeStore().isWindowAce()) {
    if (count > 10) {
      return null
    } else {
      await new Promise((resolve) => setTimeout(resolve, 600))
      postSetScript(script, count + 1)
    }
  } else {
    subscribeToDocument(script)
  }
}

/**
 * @param multiFile - check for advance IDE
 * @description - open the shared file
 */
const openSharedFile = async (multiFile: ISyncRequest = {}) => {
  const projectStore = useProjectManager().projectPermissionWithId
  const language = projectStore?.language ? projectStore?.language : useIdeStore().isLanguage
  const projectId = projectStore?.id ? projectStore?.id : useIdeStore().isProjectId
  const isAdvanced = useIdeStore().isAdvanced
  const reqBodyForSingleProj = {
    lang: language,
    type: ADVANCEDIDETYPE.OPEN,
    projectKey: isAdvanced ? useIdeStore().projectKey : null,
    projectId,
    shareId: isAdvanced ? (useIdeStore().isShareId as string) : null,
    isInstant: isAdvanced ? useIdeStore().isInstantShare : null
  }
  const reqBodyForMultiProj = {
    ...multiFile,
    projectId,
    lang: language
  }

  const reqObj = isEmpty(multiFile) ? reqBodyForSingleProj : reqBodyForMultiProj

  await axios
    .post(`/ccollab/openSharedFile`, reqObj)
    .then((response: any) => {
      postSetScript(response?.data?.id)
      useIdeStore().setIsLiveCodingActive(true)
    })
    .catch((error) => {
      throw error
    })
}
/**
 * @description - close the shared file
 * @param projectData - closing the project and resting the data
 */
const closeSharedFile = async (projectData: IsaveProjectActualRequest) => {
  const reqBody = {
    id: projectData?.id ? projectData?.id : useIdeStore().isProjectId,
    lang: projectData?.lang ? projectData?.lang : useIdeStore().isLanguage
  }
  await axios
    .post(`ccollab/cleanDoc`, reqBody)
    .then((response: any) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

/**
 * @description - subscribe to the document
 * @param id - send the id of the project
 */
const subscribeToDocument = (id: string) => {
  const ydoc = new Y.Doc()
  const type = ydoc.getText('ace')
  const WS_SERVER_URL = '/ccollab'
  provider = new WebsocketProvider(WS_SERVER_URL, id, ydoc)

  binding = new AceBinding(type, useIdeStore().codeEditor, provider.awareness)

  const user = {
    name: useAuthStore().firstName,
    color: '#' + Math.floor(Math.random() * 16777215).toString(16)
  }
  provider.awareness.setLocalStateField('user', user)
}

/**
 * @description destroying the current changes in the IDE
 */
const destroyLiveCoding = () => {
  binding && binding.destroy()
}

/**
 * @description disconnecting the live coding
 */
const disconnectLiveCoding = () => {
  try {
    provider?.disconnect()
    useIdeStore().setIsLiveCodingActive(false)
  } catch (error) {
    error
  }
}

/**
 * @description - checking if live coding is beein called
 * @returns -  live coding enable or disable
 */
const isLiveCodingNoActive = () => {
  const collab = Array.isArray(useProjectManager().collaboratorList)
    ? useProjectManager()?.collaboratorList?.filter((collab: IProject) => collab?.readOnly == false)
    : []
  if (
    !useIdeStore().isCollabId &&
    collab?.length === 0 &&
    (useProjectManager().projectPermissionWithId?.isOwner === true ||
      useProjectManager()?.projectPermissionWithId?.readOnly === true)
  ) {
    return true
  } else {
    return false
  }
}

export default {
  openSharedFile,
  destroyLiveCoding,
  closeSharedFile,
  disconnectLiveCoding,
  isLiveCodingNoActive
}
