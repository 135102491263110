import { IDECONSTANT } from '@/utils/ide'

import editorService from '@/services/ide/editor.service'
import liveCodingService from '@/services/ide/liveCoding.service'
import projectsService from '@/services/ide/projects.service'
import recaptchaService from '@/services/recaptcha.service'
import utilModelsService from '@/services/util.models.service'
import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'
import { SYNC_ACTIONS } from '@/utils/ide'
import axios from 'axios'

export interface ISyncRequest {
  id?: string
  projectKey?: string | boolean
  name?: string
  path?: string
  toPath?: string
  fromPath?: string
  type?: string
  oldName?: string
  parent?: string
  content?: string
  lang?: string
}
/**
 * Upload file to server
 * @param inputElement - The input element
 * @param item - The item to upload
 * @param itemParent - The parent of the item
 * @param duplicate - The duplicate item
 */
const uploadFileToServer = async (
  inputElement: HTMLInputElement,
  item: any,
  itemParent: string,
  duplicate: any
) => {
  if (!inputElement?.files || !inputElement.files[0]) return

  const uploadedFile = inputElement.files[0]
  const formData = new FormData()
  formData.append('projectKey', useIdeStore().projectKey as string)
  formData.append('path', itemParent)
  formData.append('file', uploadedFile)
  await axios
    .post('/api/projectSync/uploadFile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response: { data: { name: string } }) => {
      const reader = new FileReader()
      /**
       * read the file and add it to the tree
       * @param e - The event
       */
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (duplicate) {
          duplicate.content = e.target?.result
          if (
            useIdeStore().activeItem.name === duplicate.name &&
            useIdeStore().activeItem.parent === duplicate.parent
          ) {
            editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, duplicate.content)
            editorService.heightChangeFunction(IDECONSTANT.CODE_EDITOR)
          }
        } else {
          item.children.push({
            name: response.data.name,
            content: e.target?.result,
            editMode: false,
            markedForDeletion: false,
            parent: itemParent
          })
        }
        projectsService.autoSave()
      }
      reader.readAsText(uploadedFile)
      useIdeStore().setCodeUpdated(true)
    })
    .catch((error: { response: { status: number; data: { errors: Array<string> } } }) => {
      if (error?.response?.status === 403) {
        useAuthStore().clearRobotCheck()
        useIdeStore().setSyncErrorMessages('Unable to upload, please try again.')
      } else {
        useIdeStore().setSyncErrorMessages(error?.response?.data?.errors[0])
      }
    })
}
/**
 * Upload file
 * @param target - The target
 * @param item - The item to upload
 */
const uploadFile = async (target: HTMLInputElement, item: any) => {
  if (!target || !target.files || !item) {
    useIdeStore().setSyncErrorMessages('Please try again!')
    return
  }
  await recaptchaService
    .callViaCaptcha()
    .then(async () => {
      let itemParent: string = '/'
      if (item.parent && item.parent === '/') {
        itemParent = '/' + item.name
      } else if (item.parent) {
        itemParent = item.parent + '/' + item.name
      }

      let duplicate: any = null
      for (const child of item.children) {
        if (target.files && target.files[0] && child.name === target.files[0].name) {
          duplicate = child
          break
        }
      }
      if (duplicate) {
        if (duplicate?.children) {
          await utilModelsService.alertTimeOut(
            'Folder with same name found!',
            'Delete the folder with this file name, and try again.'
          )
          return
        }
        await utilModelsService
          .confirmPromise(
            'File with same name already exists in this folder',
            'Do you want to replace it?'
          )
          .then(async () => {
            uploadFileToServer(target, item, itemParent, duplicate)
          })
          .catch(() => {})
      } else {
        uploadFileToServer(target, item, itemParent, duplicate)
      }
    })
    .catch(() => {
      useIdeStore().setSyncErrorMessages('Failed to verify captcha')
    })
}
/**
 * set the code changed flag so it will tigger code change sync when active item is changed
 */
const setCodeChanged = () => {
  useIdeStore().activeItem.codeChanged = true
  useIdeStore().setCodeUpdated(true)
}
/**
 * Make the given file the home file
 * @param name - The name of the file to make the home file
 */
const makeHome = (name: string) => {
  useIdeStore().project.home = '/' + name
  useIdeStore().setCodeUpdated(true)
  projectsService.autoSave()
}
/**
 * set the active item
 * @param item - The item to set active
 */
const setActiveItem = (item: any) => {
  if (useIdeStore().isHtml && useIdeStore().isAdvanced) {
    const htmlRe = new RegExp('\\.html$')
    const jsRe = new RegExp('\\.js$')
    const cssRe = new RegExp('\\.css$')
    if (jsRe.test(item.name)) {
      useIdeStore().codeEditor.getSession().setMode('ace/mode/javascript')
    } else if (cssRe.test(item.name)) {
      useIdeStore().codeEditor.getSession().setMode('ace/mode/css')
    } else if (htmlRe.test(item.name)) {
      useIdeStore().codeEditor.getSession().setMode('ace/mode/html')
    }
  }
  useIdeStore().activeItem.content = editorService
    .getEditorSession(IDECONSTANT.CODE_EDITOR)
    .getValue()
  if (!useIdeStore().activeItem.dirtyActions) {
    useIdeStore().activeItem.dirtyActions = []
  }
  if (useIdeStore().activeItem.codeChanged === true) {
    useIdeStore().activeItem.isDirty = true
    useIdeStore().activeItem?.dirtyActions?.push({ action: SYNC_ACTIONS.FILE_CHANGED })
    useIdeStore().activeItem.codeChanged = false
    sync()
  }

  useIdeStore().activeItem = item
  if (item.content) {
    editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, item.content)
  } else {
    editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, '')
  }
  useIdeStore().activeItem = item
  if (item.yetToSync) getFileContent(item)
}
// sync utility actions
/**
 * function for sorting the tree items
 * @returns comparator function
 */
const sortComparator = () => {
  return (a: any, b: any) => {
    if (a.children && !b.children) {
      return 1
    } else if (!a.children && b.children) {
      return -1
    }

    return a.name.localeCompare(b.name)
  }
}
/**
 * sort the path
 * @param path - The path to sort
 */
const sortPath = (path: string) => {
  const parent = findEdge(path)
  parent.children.sort(sortComparator())
}
/**
 * update the childrens parent
 * @param item tree item
 */
const updateChildrensParent = (item: any) => {
  for (const child of item.children) {
    if (item.parent === '/') {
      child.parent = '/' + item.name
    } else {
      child.parent = item.parent + '/' + item.name
    }

    if (child.children) {
      updateChildrensParent(child)
    }
  }
}
/**
 * find the edge of the tree
 * @param toPath - The path to the edge
 * @returns The edge of the tree
 */
const findEdge = (toPath: string) => {
  let ancestors = ['']
  if (toPath !== '/') {
    ancestors = toPath.split('/')
  }

  let parent = useIdeStore().project.treeData
  for (let i = 0; i < ancestors.length; i++) {
    if (i === ancestors.length - 1) {
      return parent
    } else {
      parent =
        parent.children[
          parent.children.findIndex((o: any) => {
            return o.name === ancestors[i + 1]
          })
        ]
    }
  }
  return parent
}
/**
 * remove the dirty action from the item. will affect the project from store
 * @param item - The item to remove the dirty action from
 * @param dirtyAction - The dirty action to be removed
 */
const removeDirtyAction = (item: any, dirtyAction: any) => {
  item.isDirty = false
  item.dirtyActions.splice(item.dirtyActions.indexOf(dirtyAction), 1)
  useIdeStore().setCodeUpdated(true)
}
// sync actions
/**
 * sync a new item with the server
 * @param item - The item to sync
 * @param dirtyAction - The dirty action to be removed
 */
const syncNewItem = async (item: any, dirtyAction: any) => {
  const syncNewItemRequest: ISyncRequest = {
    id: useIdeStore().isProjectId,
    projectKey: useIdeStore().projectKey,
    path: item.parent,
    name: item.name,
    type: item.children ? 'folder' : 'file'
  }
  await axios
    .post('/api/projectSync/newItem', syncNewItemRequest)
    .then(() => {
      removeDirtyAction(item, dirtyAction)
    })
    .catch(() => {
      const parent = findEdge(item.parent)
      parent.children.splice(parent.children.indexOf(item), 1)
      useIdeStore().setSyncErrorMessages('Create Failed!')
    })
}
/**
 * sync a rename with the server
 * @param item - The item to sync
 * @param dirtyAction - The dirty action to be removed
 */
const syncRename = async (item: any, dirtyAction: any) => {
  const syncRenameRequest: ISyncRequest = {
    id: useIdeStore().isProjectId,
    projectKey: useIdeStore().projectKey,
    path: item.parent,
    name: item.name,
    oldName: dirtyAction.oldName
  }
  await axios
    .post('/api/projectSync/rename', syncRenameRequest)
    .then(() => {
      removeDirtyAction(item, dirtyAction)
    })
    .catch(() => {
      item.name = dirtyAction.oldName
      useIdeStore().setSyncErrorMessages('Create Failed!')
      removeDirtyAction(item, dirtyAction)
      updateChildrensParent(item)
      sortPath(item.parent)
    })
}
/**
 * sync a delete with the server
 * @param item - The item to sync
 * @param dirtyAction - The dirty action to be removed
 */
const syncDelete = async (item: any, dirtyAction: any) => {
  const syncDeleteRequest: ISyncRequest = {
    id: useIdeStore().isProjectId,
    projectKey: useIdeStore().projectKey,
    path: item.parent,
    name: item.name
  }
  await axios
    .post('/api/projectSync/delete', syncDeleteRequest)
    .then(() => {
      const parentEdge = findEdge(item.parent)
      parentEdge.children.splice(parentEdge.children.indexOf(item), 1)
      useIdeStore().setCodeUpdated(true)
    })
    .catch(() => {
      item.markedForDeletion = false
      removeDirtyAction(item, dirtyAction)
      useIdeStore().setSyncErrorMessages('Delete Failed!')
    })
}
/**
 * sync a item move with the server
 * @param item - The item to sync
 * @param dirtyAction - The dirty action to be removed
 */
const syncItemMoved = async (item: any, dirtyAction: any) => {
  const syncItemMovedRequest: ISyncRequest = {
    id: useIdeStore().isProjectId,
    projectKey: useIdeStore().projectKey,
    toPath: item.parent,
    name: item.name,
    fromPath: dirtyAction.oldPath
  }
  await axios
    .post('/api/projectSync/move', syncItemMovedRequest)
    .then(() => {
      removeDirtyAction(item, dirtyAction)
    })
    .catch(() => {
      useIdeStore().setSyncErrorMessages('Move Failed!')

      // Move Back
      const oldParent = findEdge(dirtyAction.oldPath)
      const currentParent = findEdge(item.parent)
      currentParent.children.splice(currentParent.children.indexOf(item), 1)
      oldParent.children.push(item)
      item.parent = dirtyAction.oldPath
      sortPath(dirtyAction.oldPath)

      // Reinstansiate old file if any
      if (dirtyAction.replacedItem) {
        currentParent.children.push(dirtyAction.replacedItem)
        sortPath(dirtyAction.replacedItem.parent)
      }
      updateChildrensParent(item)
      removeDirtyAction(item, dirtyAction)
    })
}
/**
 * sync a file change with the server
 * @param item - The item to sync
 * @param dirtyAction - The dirty action to be removed
 */
const trySyncFileChanged = async (item: any, dirtyAction: any) => {
  const syncFileChangeRequest: ISyncRequest = {
    id: useIdeStore().isProjectId,
    projectKey: useIdeStore().projectKey,
    name: item.name,
    parent: item.parent,
    content: item.content
  }
  await recaptchaService
    .callViaCaptcha()
    .then(async () => {
      await axios
        .post('/api/projectSync/updateFile', syncFileChangeRequest)
        .then(async () => {
          removeDirtyAction(item, dirtyAction)
        })
        .catch((error: { response: { status: number } }) => {
          if (error.response.status === 403) {
            useAuthStore().clearRobotCheck()
          }
          useIdeStore().setSyncErrorMessages('Unable to Sync the file with server!')
        })
    })
    .catch((error: any) => {
      useIdeStore().setSyncErrorMessages(error.message || 'Failed to verify captcha')
    })
}
/**
 * get the file content from the server
 * @param item - The item to get the content from
 */
const getFileContent = async (item: any) => {
  const getFileContentRequest: ISyncRequest = {
    id: useIdeStore().isProjectId,
    projectKey: useIdeStore().projectKey,
    name: item.name,
    path: item.parent
  }
  await axios
    .post('/api/projectSync/getFileContent', getFileContentRequest)
    .then((response: { data: { content: string } }) => {
      if (useIdeStore().isLiveCodingActive) {
        liveCodingService.disconnectLiveCoding()
        liveCodingService.openSharedFile({
          projectKey: useIdeStore().projectKey,
          name: item.name,
          path: item.parent
        })
      } else editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, response.data.content)
      item.yetToSync = false
    })
}
/**
 * check if the item is dirty
 * @param items - items to check
 * @returns true if the item is dirty
 */
const dirtyCheck = (items: any) => {
  for (const item of items) {
    if (item.isDirty === true) {
      return false
    }

    if (item.children) {
      if (!dirtyCheck(item.children)) {
        return false
      }
    }
  }
  return true
}
/**
 * sync with the server
 * @param items - item with sync actions
 */
const syncWorker = async (items: any[]) => {
  for (const item of items) {
    if (item.isDirty) {
      if (!item.dirtyActions || item.dirtyActions.length < 1) {
        item.isDirty = false
      }
      for (const dirtyAction of item.dirtyActions) {
        switch (dirtyAction.action) {
          case SYNC_ACTIONS.NEW_ITEM:
            await syncNewItem(item, dirtyAction)
            break
          case SYNC_ACTIONS.RENAME:
            await syncRename(item, dirtyAction)
            break
          case SYNC_ACTIONS.DELETE:
            await syncDelete(item, dirtyAction)
            break
          case SYNC_ACTIONS.ITEM_MOVED:
            await syncItemMoved(item, dirtyAction)
            break
          case SYNC_ACTIONS.FILE_CHANGED:
            await trySyncFileChanged(item, dirtyAction)
            break
        }
      }
    }
    if (item.children) {
      await syncWorker(item.children)
    }
  }
}
/**
 * Sync the project
 * @param onAutoSave - The auto save flag
 */
const sync = async (onAutoSave: boolean = false) => {
  if (useIdeStore().syncInProgress === true) return

  useIdeStore().syncInProgress = true
  await syncWorker(useIdeStore().project.treeData.children)
  useIdeStore().syncInProgress = false
  if (!onAutoSave) projectsService.autoSave()
}
/**
 * check if the project is synced
 * @returns true if the project is synced
 */
const isSyncSuccess = () => {
  return dirtyCheck(useIdeStore().project.treeData.children)
}
/**
 * sync before execute
 * @param autoSave - The auto save flag
 */
const syncBeforeExecute = async (autoSave: boolean = false) => {
  if (useIdeStore().activeItem.codeChanged === true) {
    useIdeStore().activeItem.isDirty = true
    if (!useIdeStore().activeItem.dirtyActions) {
      useIdeStore().activeItem.dirtyActions = []
    }
    useIdeStore().activeItem?.dirtyActions?.push({ action: SYNC_ACTIONS.FILE_CHANGED })
    useIdeStore().activeItem.content = editorService
      .getEditorSession(IDECONSTANT.CODE_EDITOR)
      .getValue()
    useIdeStore().activeItem.codeChanged = false
  }

  await sync(autoSave)
}
export default {
  uploadFile,
  setCodeChanged,
  makeHome,
  setActiveItem,
  sortComparator,
  sortPath,
  updateChildrensParent,
  findEdge,
  sync,
  isSyncSuccess,
  syncBeforeExecute
}
